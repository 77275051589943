import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Container } from "react-bootstrap";
import { Card } from 'primereact/card';
import News from '../Pages/News';

const items = [
    {
        Title: "За компанията",
        LinkPath: "/about",
        Image: '/english-4729683_1920.jpg'
    },
    {
        CustomContent: <Link to="/news" className="slideLinkNoHref"><News id="0"/></Link>
    },
    {
        Title: "Курсове",
        LinkPath: "/trainings",
        Image: '/woman-4702060_1920.jpg'
    },
    {
        Title: "Контакт",
        LinkPath: "/contact",
        Image: '/contact-us-2993000_1920.jpg'
    }
]

function Slides() {
    return (<Container>
        <Carousel
            autoPlay={true}
            interval={4000}
            infiniteLoop={true}
            statusFormatter={() => ""}
            showIndicators={true}
            showThumbs={false}
            showArrows={true}
            dynamicHeight={false}
        >
            {items.map(slide =>
                {
                    if(slide.CustomContent) {
                        return slide.CustomContent
                    } else {
                        return <Card title={slide.Title}>
                            <Link to={slide.LinkPath} className="slideLink">
                                <img src={slide.Image} alt={slide.Title} className="slideImage"/>
                            </Link>
                        </Card>
                    }
                } 
            )}
        </Carousel>
    </Container>
    );
}

export default Slides;