import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Icon } from '@fluentui/react/lib/Icon';
import { Card } from 'primereact/card';

function Contact() {
    return <Container>
        <Card title="За контакт">
            <Row>
                <Col xs="12"><Icon iconName="BusinessCenterLogo" /> Ейнджълс 77 ЕООД</Col>
                <Col xs="12"><Icon iconName="Contact" /> Пламен Балинов (мениджър обучения)</Col>
                <Col xs="12"><Icon iconName="CityNext2" /> Централен офис - София, кв. Манастирски Ливади, сграда Мона</Col>
                <Col xs="12"><Icon iconName="Mail" /> <a href="mailto:plamen_balinov@abv.bg">plamen_balinov@abv.bg</a></Col>
                <Col xs="12"><Icon iconName="Phone" /> 0887 74 51 16</Col>
            </Row>
        </Card>
    </Container>

}

export default Contact;