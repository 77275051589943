import Container from 'react-bootstrap/Container';
import { Card } from 'primereact/card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Icon } from '@fluentui/react/lib/Icon';

function About() {
    return <Container>
        <Card title="За фирмата">
            <p>В продължение на години English Angels Schools обучава водещи
                фирми в гр. София, Варна и в страната, както и извършва преводаческа дейност към
                други. Работили сме успешно с Министерството на икономиката, енергетиката и
                туризма, както и с фирми като &quot;Пико&quot;, &quot;СОТ 1&quot;, Застрахователно дружество „ЛевИнс”,
                “Веломания”, МБАЛ Света Екатерина, МБАЛ Свети Наум и много други. Имаме и
                спечелен проект за обучение по английски език на Европейския Парламент в България.
                По програми, които са финансирани от Европейския съюз, сме обучили над 2500 лица
                по английски и немски език.</p>
        </Card>
        <br />
        <Card title="Локации">
            <p>Основният ни учебен център в София е разположен в квартал Овча купел
                1. Езикова школа Ейнджълс 77 има възможност да организира курсове по заявки за
                заети лица на работното място или в близост до него при сформиране на достатъчна по
                големина група.</p>
        </Card>
        <br />
        <Card title="Референции">
            <Row>
                <Col><Icon iconName="PDF" /> <a href='/pdf za Plamen IMG_20230727_194119-1 (1).pdf' target="_blank" rel="noreferrer">Съюз на българските автомобилисти</a></Col>
            </Row>
            <Row>
                <Col><Icon iconName="PDF" /> <a href='/pdf za Plamen IMG_20230727_194119-3 (1).pdf' target="_blank" rel="noreferrer">Радио СВ такси</a></Col>
            </Row>
        </Card>
    </Container>
}

export default About;