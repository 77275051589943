import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Route, Routes, BrowserRouter
} from "react-router-dom";
import About from './Pages/About';
import MainPage from './Pages/MainPage';
import News from './Pages/News';
import Trainings from './Pages/Trainings';
import Contact from './Pages/Contact';
import Slides from './Pages/Slides';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import "primereact/resources/themes/lara-light-indigo/theme.css"
import "primereact/resources/primereact.min.css";  

function App() {
  initializeIcons(/* optional base url */);
  return <><BrowserRouter>
    <Routes>
      <Route path="/" element={<MainPage />}>
        <Route path="/" element={<Slides />} />
        <Route path="/about" element={<About />} />
        <Route path="/news/" element={<News/>} />
        <Route path="/news/:id" element={<News />} />
        <Route path="/trainings" element={<Trainings />} />
        <Route path="/contact" element={<Contact />} />
      </Route>
    </Routes>
  </BrowserRouter>

  </>;
}

export default App;
