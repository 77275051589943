import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Card } from 'primereact/card';
import { useParams } from "react-router-dom";
import news from "../NewsContent/news.json";

function News(props) {
    const params = useParams();
    const newsId = params.id || props.id;
    
    const getNews = (n) => {
        return  <>
                    <Row>
                        <Col>
                            <Card title={n.Title}>
                                <Row>
                                    <Col><img src={'/' + n.Image } alt="news_image" className='newsImage'/></Col>
                                    <Col>
                                        <p className="m-0">
                                            {n.Text}
                                        </p>
                                    </Col>
                                </Row>
                                
                            </Card>
                        </Col>
                    </Row>
                    <br/>
                </>
    }

    const outputNews = (newsId, news) => {
        if(newsId) {
            return getNews(news[newsId]);
        } else {
            return news.map(n => getNews(n));
        }
    };

    if(!newsId) {
        return <Container><Card title="Последни новини">{ outputNews(newsId, news) }</Card></Container>;
    } else {
        return <Container> {outputNews(newsId, news)} </Container>;
    }
}

export default News;