import { Outlet, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Icon } from '@fluentui/react/lib/Icon';
import { Menubar } from 'primereact/menubar';

function MainPage() {
  const items = [
    {
      label: <Link to="/"><Icon iconName="HomeSolid" /></Link>
    },
    {
      label: <Link to="/about">За компанията</Link>
    },
    {
      label: <Link to="/news">Новини</Link>
    },
    {
      label: <Link to="/trainings">Курсове</Link>
    },
    {
      label: <Link to="/contact">Контакт</Link>
    },   
 ];

  return  <Container>
            <Row>
              <Col><img className="logo" src='/angels77 logo.svg' alt="web_logo"/></Col>
            </Row>
            <Row>
              <nav>
              <Container>
                <Menubar model={items}/>
                </Container>   
              </nav>
            </Row>
            
            <Outlet />
          </Container>   
}

export default MainPage;
