import Container from 'react-bootstrap/Container';
import { Card } from 'primereact/card';
import { Icon } from '@fluentui/react/lib/Icon';

function Trainings() {
    return <Container>
            <Card title="За курсовете">
                    <p className="m-0">Продължителността на безплатния курс е 300 учебни часа, които
                    при идеални условия покриват три нива на владеене на езика. Времетраенето на курса
                    варира в зависимост от заетостта на обучаемите между 12 уч. ч. и 20 уч. ч. седмично.
                    Всяко лице полага входящ тест за определяне на нивото на неговите знания по
                    английски език. Целта е да се определи начално ниво за стартиране на курса и при
                    множество курсисти разделяне на групи.</p>
            </Card>
            <br />
            <Card title="За системата на обучение">
                    <p className="m-0">Обучението по общ английски език се провежда по системата
                    Upstream, която е водещата световна система на издателство Express Publishing. Това
                    е система за изучаване на английски език, която е структурирана на лексикална основа
                    и е с основен акцент върху РАЗГОВОРНИЯ английски език. Разпределена е в шест
                    нива и обхваща необходимите компоненти за свободното опериране с езика - четене с
                    разбиране, слушане с разбиране, писане и говорни умения. Специалните упражнения за
                    комуникация, включени във всеки модул развиват свободния разговор на английски
                    език, а именно това са упражненията, които пресъздават реални ситуации и спомагат за
                    търсене начини на изразяване и боравене със заучени граматични структури и нова
                    лексика. Бизнес английски език- <u><b>Обучението е по система, която да отговаря на
                        нуждите на бизнеса в съответната компания. Използват се множество допълнителни
                        материали с цел повишаване динамиката и мотивацията за учене. За обучения по
                        специализиран английски език, системите са различни, съобразно сферата на
                        работа на учащите!</b></u></p>
                        <br/>
                <Icon iconName="PDF" /> <a href='/upstream_pre_intermediate_leaflet-1 (1).pdf' target="_blank" rel="noreferrer">Upstream</a>
            </Card>
            <br />
            <Card title="За преподавателите">
                    <p className="m-0">Обучението се провежда от квалифицирани
                    преподаватели по английски език, специализирали в страната и чужбина. Учителят
                    преценява силните и слабите страни на обучаемия и работи в негова полза по време на
                    курса. Управителят на школата е с педагогическо образование, магистър по
                    икономика и е сертифициран за преподаване на английски език за възрастни от
                    университета в Кеймбридж.</p>
            </Card>
                
       
    </Container>
}

export default Trainings;